const apiUrl = process.env.REACT_APP_API_URL;

const getProducts = async (req, res) => {
    const user = await localStorage.getItem('user')

    const token = await JSON.parse(user).token
    if (!token) {
       return null
    }

    const response = await fetch(`${apiUrl}/brand/products`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    })
    const data = await response.json()
    const products = data.data
    return (products)
}


export default {
    getProducts
}