// BrandController.js
const apiUrl = process.env.REACT_APP_API_URL;


const apiBaseUrl = `${apiUrl}/publisher/brands`; // Base URL for your brands API

const getFetch = async (dir) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const url = `${apiUrl}/${dir}`
    const options = {
        headers: {
            Authorization: `${user?.token}`
        }
    }
    return fetch(url, options)
}



// Fetch all brands
const fetchBrands = async () => {
    try {
        const response = await getFetch('publisher/brands')
        const data = await response.json();
        return data
    } catch (error) {
        console.error('Error fetching brands:', error);
        throw error;
    }
};

// Fetch a single brand by ID
const fetchBrandById = async (id) => {
    try {
        const response = await fetch(`${apiBaseUrl}/${id}`);
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error(`Error fetching brand with ID ${id}:`, error);
        throw error;
    }
};

// Add a new brand
const addBrand = async (brandData) => {
    try {
        const response = await fetch(apiBaseUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(brandData),
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error('Error adding new brand:', error);
        throw error;
    }
};

// Update an existing brand
const updateBrand = async (id, brandData) => {
    try {
        const response = await fetch(`${apiBaseUrl}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(brandData),
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error(`Error updating brand with ID ${id}:`, error);
        throw error;
    }
};

// Delete a brand
const deleteBrand = async (id) => {
    try {
        const response = await fetch(`${apiBaseUrl}/${id}`, {
            method: 'DELETE',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error(`Error deleting brand with ID ${id}:`, error);
        throw error;
    }
};

export default {
    fetchBrands,
    fetchBrandById,
    addBrand,
    updateBrand,
    deleteBrand,
};
