import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Container, Dialog, FormControlLabel, Input, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";

import Logo from '../../assets/ec_logo.svg';
import ECCard from "../../components/ECCard";
import ECCheckBox from "../../components/ECCheckBox";
import ECButton from "../../components/ECButton";
import ECInput from "../../components/ECInput";
import { ArrowCircleDown, ArrowDownward, ChatBubble, ChatBubbleOutline, Check, ChevronLeft, ExpandLess, ExpandMore, HourglassBottom, HourglassEmpty, HourglassTop, Pending, QuestionAnswer, QuestionAnswerOutlined, QuestionMark } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmitPage from "./SubmitPage";
import PaywallPage from "./PaywallPage";
import { styled } from '@mui/material/styles';
import LoginPage from "./LoginPage";


const MainPage = () => {



    const impacts = [
        {
            "category": "Climate",
            "requirements": [
                {
                    "code": "C1",
                    "name": "Use of energy-efficient appliances (Class A++ or better)",
                    "verification": "Proof Documentation",
                    "details": "List of appliances with model name and corresponding efficiency class"
                },
                {
                    "code": "C2",
                    "name": "Implementation of at least 80% LED lighting throughout the restaurant",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "C3",
                    "name": "Utilizing energy-saving modes on all electronic devices (if applicable)",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "C4",
                    "name": "Integration of smart thermostat systems to optimize heating and cooling",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "C5",
                    "name": "Participation in carbon offset programs to mitigate emissions",
                    "verification": "Proof Documentation",
                    "details": "Scan of certification document"
                },
                {
                    "code": "C6",
                    "name": "Offering at least one plant-based menu option",
                    "verification": "Proof Documentation",
                    "details": "Scan of menu"
                },
                {
                    "code": "C7",
                    "name": "Regular maintenance of equipment to ensure optimal efficiency",
                    "verification": "Proof Documentation",
                    "details": "Scan of maintenance contract or last invoice (not older than 1 year)"
                },
                {
                    "code": "C8",
                    "name": "Avoidance of standby modes on devices (when not in use)",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "C9",
                    "name": "Training staff in energy-conscious behaviors",
                    "verification": "Proof Documentation",
                    "details": "Scan of training materials"
                }
            ]
        },
        {
            "category": "Environment",
            "requirements": [
                {
                    "code": "E1",
                    "name": "Energy is coming from renewable sources (at least 50%)",
                    "verification": "Proof Documentation",
                    "details": "Invoice of electricity provider (not older than 1 year)"
                },
                {
                    "code": "E2",
                    "name": "Preference for regional products (at least 30% of all ingredients)",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "E3",
                    "name": "Participation in reforestation initiatives to support biodiversity",
                    "verification": "Proof Documentation",
                    "details": "Scan of certification document"
                },
                {
                    "code": "E4",
                    "name": "Adoption of cleaning products with biodegradable formulas in reusable or compostable containers",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "E5",
                    "name": "Usage of recycled paper for all paper products in the kitchen and guest and/or staff toilets",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "E6",
                    "name": "Usage of refillable hand detergent containers in guest and/or staff toilets",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "E7",
                    "name": "Training employees on environmentally friendly practices",
                    "verification": "Proof Documentation",
                    "details": "Scan of training materials"
                }
            ]
        },
        {
            "category": "Waste",
            "requirements": [
                {
                    "code": "W1",
                    "name": "Implementation of a comprehensive recycling process for all waste streams",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "W2",
                    "name": "Substitution of single-use plastics through alternative packaging options, that are biodegradable or compostable",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "W3",
                    "name": "Donating surplus food to local charities",
                    "verification": "Proof Documentation",
                    "details": "Scan of cooperation contract"
                },
                {
                    "code": "W4",
                    "name": "Introduction of reusable work clothes and aprons",
                    "verification": "Self Assessment",
                    "details": ""
                },
                {
                    "code": "W5",
                    "name": "Training staff on waste reduction and recycling practices",
                    "verification": "Proof Documentation",
                    "details": "Scan of training materials"
                }
            ]
        }
    ];


    const [selectedCodes, setSelectedCodes] = useState([]);

    const [selected, setSelected] = useState([]);

    const [additionalInfo, setAdditionalInfo] = useState([]);

    const [categoryOpen, setCategoryOpen] = useState([]);

    const [showSummary, setShowSummary] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const navigate = useNavigate();

    const [user, setUser] = useState(null);

    const [loadedFromStorage, setLoadedFromStorage] = useState(false);


    const renderStatus = (requirement) => {

        if (!selected.find(s => s.code === requirement.code)) return <></>

        if (requirement.verification === 'Proof Documentation') {
            return <Check style={{ fill: '#g7FF22' }} />

         //   return <HourglassTop style={{ fill: '#FDE700' }} />

        }
        if (requirement.verification === 'Self Assessment') {
            return <Check style={{ fill: '#g7FF22' }} />
        }
        else {
            return <QuestionAnswerOutlined style={{ fill: '#FF6722' }} />

        }
    }

    const [activePlan, setActivePlan] = useState({ label: 'Free', code: 'free' });

    const renderPlan = (plan) => {
        return (
            <Box sx={{ mt: 1 }}>
                <p>Your current plan is <b>{plan.label}</b></p>
                <p>To unlock all features, please upgrade to a premium plan.</p>
            </Box>
        )
    }

    const toggleSelected = (impact) => {
        if (selected.find((item) => item.code === impact.code)) {
            setSelected(selected.filter((item) => item.code !== impact.code));
        }
        else {
            setSelected([...selected, impact]);
        }
    }

    const toggleAdditionalInfo = (impact) => {
        if (additionalInfo.find((item) => item.code === impact.code)) {
            setAdditionalInfo(additionalInfo.filter((item) => item.code !== impact.code));
        }
        else {
            setAdditionalInfo([...additionalInfo, impact]);
        }
    }

    const toggleCategoryOpen = (category) => {
        if (categoryOpen.find((item) => item === category)) {
            setCategoryOpen(categoryOpen.filter((item) => item !== category));
        }
        else {
            setCategoryOpen([...categoryOpen, category]);
        }
    }

    const Input = styled('input')({
        display: 'none'
    });


    useEffect(() => {
        if (localStorage.getItem('user')) {
            setUser(JSON.parse(localStorage.getItem('user')));
        }

        if (localStorage.getItem('selected')) {
            const loaded = localStorage.getItem('selected')
            if (loaded.length > 0) {
                setSelected(JSON.parse(loaded));
                setLoadedFromStorage(true);
            }
        }
    }, [])

    useEffect(() => {
        if (selected.length > 0 || loadedFromStorage)
            localStorage.setItem('selected', JSON.stringify(selected))
    }, [selected])

    return (
        <>

            <Container maxWidth="md" sx={{ height: '100%' }}>

                <Box sx={{ mt: 0 }}>
                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}
                        sx={{ bgcolor: '#fff', position: 'sticky', top: 0, zIndex: 1000 }}
                    >

                        <Box sx={{ pt: 2, pb: 1 }}>
                            <img src={Logo} height={'20px'} width={'auto'} alt="EC logo" />
                        </Box>

                        <Box sx={{ pt: 1 }}>
                            {showSummary ?
                                <ECButton onClick={() => setShowSummary(false)} variant="contained" color="warning">Change</ECButton>
                                :
                                <ECButton fullWidth disabled={selected.length <= 0} onClick={() => {
                                    setShowSummary(true)
                                }} variant="contained" color="primary">Continue</ECButton>
                            }
                        </Box>

                    </Stack>

                    {!showSummary ?

                        <Stack sx={{ mt: 2, mb: 2 }} direction={'column'} spacing={2} justifyContent={'space-between'}>
                            {impacts.map((category, index) => (
                                <ECCard key={index}>


                                    <AccordionSummary sx={{ m: 0, p: 0 }}
                                    >
                                        <Stack direction={'row'} spacing={1} justifyContent={'space-between'} flex={1}>
                                            <h3>{category.category}</h3>
                                            <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
                                                <Box>
                                                    {
                                                        selected.filter(s => category.requirements.find(r => r.code === s.code)).length
                                                    }/{category.requirements.length}
                                                </Box>
                                            </Stack>

                                        </Stack>

                                    </AccordionSummary>
                                    <AccordionDetails sx={{ p: 0 }}>

                                        <List>
                                            {category.requirements.map((requirement, index) => (
                                                <Accordion disableGutters sx={{ mb: 1 }} expanded={selected.find(r => r.code == requirement.code)} key={index} onChange={(e) => {
                                                }}>
                                                    <AccordionSummary onClick={() => toggleSelected(requirement)}>

                                                        <ListItemText onClick={() => toggleSelected(requirement)}>

                                                            <FormControlLabel
                                                                control={<Checkbox checked={selected.find(s => s.code == requirement.code)}
                                                                    onChange={() => { toggleSelected(requirement) }}
                                                                />}
                                                                label={requirement.name}

                                                            />
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            {renderStatus(requirement)}

                                                        </ListItemSecondaryAction>

                                                    </AccordionSummary>
                                                    {
                                                        (requirement.verification === 'Proof Documentation' && 1==2) &&
                                                        <AccordionDetails sx={{ pl: 2, pr: 2 }}>


                                                            


                                                            {
                                                                (requirement.verification === 'Self Assessment' && 1 == 2) &&
                                                                <>
                                                                    <Box sx={{ m: 2 }}>
                                                                        <Checkbox onChange={(e) => {
                                                                            toggleAdditionalInfo(requirement)
                                                                        }} label={'I want to add additional information'} />
                                                                        {additionalInfo.find((item) => item.code === requirement?.code) &&
                                                                            <ECInput label={'comment'} onChange={() => { return null }} multiline rows={4} fullWidth={true} />
                                                                        }

                                                                    </Box>
                                                                </>
                                                            }



                                                        </AccordionDetails>
                                                    }
                                                </Accordion>

                                            ))}
                                        </List>

                                    </AccordionDetails>

                                </ECCard>

                            ))

                            }




                            <ECButton fullWidth disabled={selected.length <= 0} onClick={() => {
                                setShowSummary(true)
                            }} variant="contained" color="primary">Continue</ECButton>



                        </Stack>
                        :
                        <>



                            <SubmitPage selected={selected} renderStatus={renderStatus} setSubmitting={setSubmitting} />
                            <p></p>


                            {(activePlan.code === 'free' && submitting) &&
                                <>

                                    <Dialog open={true} onClose={() => setSubmitting(false)} sx={{ m: 0 }} >

                                        <Container>
                                            <PaywallPage setActivePlan={setActivePlan} />

                                        </Container>
                                    </Dialog>
                                </>
                            }
                        </>

                    }
                </Box>
            </Container>




            {!user &&
                <Dialog open={true} maxWidth={'md'}>
                    <LoginPage />
                </Dialog>
            }

        </>
    );
}

export default MainPage;

