import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ECCard from "../../components/ECCard";
import ECInput from "../../components/ECInput";
import { Container, Box, Stack, Grid, Chip } from "@mui/material";
import ECButton from "../../components/ECButton";
import { Add, ArrowBack, Redo, Save, Undo } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";

import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;


const ProductEdit = () => {

    const { id } = useParams();
    const [product, setProduct] = useState({ Declaration: [], ProductOnChannel: [], channels: [] });
    const [storedProduct, setStoredProduct] = useState({ Declaration: [] });

    const [productHasChanged, setProductHasChanged] = useState(false);

    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();

    const { t } = useTranslation()


    useEffect(() => {

        if (id == 'new') {

            return
        }

        const fetchProduct = async () => {
            setIsLoading(true)
            const response = await fetch(`${apiUrl}/products/${id}/view`)
            const data = await response.json()
            setProduct(data)
            setStoredProduct(data)
            setIsLoading(false)
        }

        if (id === "new") {
            setProduct({ name: '', ean: '', description: '', price: 0 })
            setStoredProduct({ name: '', ean: '', description: '', price: 0 })
        } else {

            fetchProduct();
        }
    }, [id])

    const postProductUpdate = async (product) => {
        const productId = product.id ? product.id : crypto.randomUUID()
        const productData = {
            id: productId,
            name: product.name,
            ean: product.ean,
            description: product.description,
            price: product.price,
        }
        const response = await fetch(`${apiUrl}/products/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': JSON.parse(localStorage.getItem('user'))["token"]

            },
            body: JSON.stringify({ productData: productData })
        })
        const data = await response.json()
        setStoredProduct(data)
        setProductHasChanged(false)
    }



    useEffect(() => {
        if (JSON.stringify(storedProduct) != JSON.stringify(product)) {
            setProductHasChanged(true)
        } else {
            setProductHasChanged(false)
        }
    }, [product])


    return (
        <Container maxWidth={'xl'}>
            <h1><i className="fa-regular fa-pen-to-square"></i> Edit Product</h1>
        
            <Stack direction={'row'} justifyContent={'flex-start'} spacing={1} sx={{ mb: 1 }}>
                <ECButton startIcon={<ArrowBack />} label="Back" onClick={() => window.history.back()} />
                {productHasChanged && <>

                    <ECButton label="Discard Changes" startIcon={<Undo />} onClick={() => {
                        setProduct(storedProduct)

                    }
                    } />

                    <ECButton label="Save Product Changes" startIcon={<Save />} variant={'contained'} onClick={() => {
                        postProductUpdate(product)
                    }
                    } />


                </>}
            </Stack>
            {!product || isLoading ? <p>Loading...</p> :
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <ECCard>
                                <h2>Basic</h2>
                                <Stack direction="column" spacing={2} justifyContent={'flex-start'}>
                                    <ECInput label="Name" value={product?.name} fullWidth onChange={(e) => { setProduct({ ...product, name: e.target.value }) }} />
                                    <ECInput label="EAN" value={product?.ean} fullWidth onChange={(e) => setProduct({ ...product, ean: e.target.value })} />
                                </Stack>
                            </ECCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ECCard>
                                <h2>Image</h2>
                                {product?.image_url ?
                                    <img src={product?.image_url} alt={product?.name} style={{ height: '128px' }} /> :
                                    <i class="fa-solid fa-box"></i>}
                            </ECCard>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <ECCard>
                                <h2>Declarations</h2>

                                <Box>
                                    <DataGrid rows={product?.Declaration} columns={[
                                        {
                                            field: 'ProofPoint', headerName: 'Proof Point', flex: 0.8, renderCell: (params) =>
                                                <>
                                                    <img src={params.row.ProofPoint.image_url} />

                                                </>
                                        },
                                        { field: 'status', headerName: 'Status', flex: 0.2, renderCell: (params) => <Chip label={params.row.status} /> },
                                        { field: 'createdAt', headerName: 'Created', flex: 0.1 },
                                        { field: 'updatedAt', headerName: 'Updated', flex: 0.1 }
                                    ]}
                                        onRowDoubleClick={(params) => {
                                            navigate('/proofpoints/' + params.row.DeclarationGroup.id)
                                        }}
                                    />
                                    <ECButton label="Add Declaration" startIcon={<Add />} sx={{ mt: 1 }} onClick={() => navigate('/proofpoints/new?p=' + product.id)} />
                                </Box>

                            </ECCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ECCard>
                                <h2>Channels</h2>
                                <Box>
                                    {product?.channels?.length <= 0 ? <p>No channels configured</p> :
                                        <DataGrid rows={product?.channels} columns={[
                                            { field: 'name', headerName: 'name', flex: 0.8 },
                                            { field: 'status', headerName: 'Status', flex: 0.2 }
                                        ]}
                                            onRowDoubleClick={(params) => {
                                                navigate('/channels/' + params.row.id)
                                            }}
                                        />}

                                    <ECButton label="Add Channel" startIcon={<Add />} sx={{ mt: 1 }} onClick={() => navigate('/channels/new?p=' + product.id)} />
                                </Box>
                            </ECCard>
                        </Grid>
                    </Grid>
                </Box>
            }
        </Container>
    );
}

export default ProductEdit;