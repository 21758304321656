import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";
import ECButton from "../../components/ECButton";
import { useState } from "react";
import { FileUpload } from "@mui/icons-material";
import ECInput from "../../components/ECInput";

const apiUrl = process.env.REACT_APP_BASE_URL;

const UploadSection = ({ declarationGroup, setDeclarationGroup }) => {

    const [open, setOpen] = useState(false)
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState('');
    // Handle file input change
    const handleFileChange = (event) => {
        setFile(event.target.files[0]); // Set the selected file
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    }


    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('file', file); // 'file' is the key expected by your NestJS backend

        try {
            console.log("uploading file to ", apiUrl)
            const response = await fetch(`${apiUrl}/file`, {
                method: 'POST',
                body: formData,
            });


            const data = await response.json();

            console.log(data);

            if (response.ok) {
                // alert('File uploaded successfully: ' + JSON.stringify(data));

                setDeclarationGroup({ ...declarationGroup, documents: [...declarationGroup?.documents || [], { name: data.fileInfo.originalName, title: title, url: data.fileInfo.url }] })

            } else {
                const error = await response.text();
                throw new Error(error);
            }
        } catch (error) {
            alert('Error uploading file: ' + error.message);
        }
    };



    return (
        <>
            <List>

                {declarationGroup.documents?.map((doc) => {
                    return <ListItem>
                        <ListItemAvatar>
                            <Link href={doc.url} target="_blank">
                              <i class="fa-solid fa-file"/>
                            </Link>
                        </ListItemAvatar>
                        <ListItemText primary={doc.title} secondary={<>  <Link href={doc.url} target="_blank">{doc.name}</Link></>}>{doc.title || doc.name}</ListItemText>

                        <ListItemSecondaryAction>
                            <ECButton variant={'text'} onClick={()=>
                                setDeclarationGroup({...declarationGroup, documents: declarationGroup.documents.filter((d) => d.url !== doc.url)})
                            }><i class="fa-solid fa-xmark"></i></ECButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                })
                }
                {!declarationGroup.documents?.length > 0 && <ListItem>
                    <ListItemText secondary={'No documents uploaded'}></ListItemText>
                </ListItem>}
                <ECButton label="Upload Document" onClick={() =>
                    setOpen(true)
                } />
            </List>
            <Dialog open={open} onClose={() => setOpen(false)}>

                <DialogTitle>
                    Upload Document
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 1 }}>
                        <Stack direction={'column'} spacing={1}>
                            <ECInput type="file" label="Select Document" fullWidth focus onChange={handleFileChange} />
                            <ECInput label="Document Name" fullWidth onChange={handleTitleChange} />
                        </Stack>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <ECButton label="Cancel" onClick={() => setOpen(false)} />
                    <ECButton startIcon={<FileUpload />} label="Upload" variant='contained' onClick={() => {
                        handleUpload();

                        setOpen(false)
                    }} />
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UploadSection;