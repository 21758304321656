import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Container, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";
import ECCard from "../../components/ECCard";
import ECInput from "../../components/ECInput";
import ECButton from "../../components/ECButton";
import { ArrowBack, CallSplitSharp, ContentPasteOutlined, DeleteOutlineOutlined, Key, Save, Undo } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import ProductSelectDialog from "../proofpoints/ProductSelectDialog";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import ECBlurEffect from "../../components/ECBlurEffect";


const ChannelEdit = () => {
    const [channel, setChannel] = useState({
        name: '',
        url: '',
        status: 'Active',
        products: [],
        template: 'default'



    })

    const apiUrl = process.env.REACT_APP_API_URL;
    const widgetUrl = process.env.REACT_APP_WIDGET_URL
    const baseUrl = process.env.REACT_APP_BASE_URL

    const [storedChannel, setStoredChannel] = useState({})

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [isProductSelectDialogOpen, setIsProductSelectDialogOpen] = useState(false)

    const { id } = useParams()

    const [widgetSnippet, setWidgetSnippet] = useState('')
    const [widgetSnippetShopify, setWidgetSnippetShopify] = useState('')

    const [styleSheet, setStyleSheet] = useState(`
    /* Add your custom styles here */
    body {
        font-family: Arial, sans-serif;
    }
    img {
        max-width: 100%;
    }
    .row {
        display: flex;
        flex-wrap: wrap;
    }
    `)

    const navigate = useNavigate()


    useEffect(() => {
        if (id == "new") setChannel({ name: '', url: '', status: 'Active', products: [] })

        const fetchChannel = async () => {
            const token = JSON.parse(localStorage.getItem('user')).token;

            const response = await fetch(`${apiUrl}/channels/${id}`, {
                headers: {
                    Authorization: token
                }
            })
            const data = await response.json()
            setChannel({ ...data, template: data.template || 'default' })
            setStoredChannel(data)

            setWidgetSnippet(`<ecocheck id="ecocheck-widget-container"/>
<script src="${widgetUrl}/222333444"></script>`)


            setWidgetSnippetShopify(`<ecocheck id="ecocheck-widget-container"></ecocheck>

<script src="${widgetUrl}/${id}/{{ product.variants[0].barcode }}"></script>`)
        }



        fetchChannel()



    }, [id])

    const postChannel = async () => {
        const token = JSON.parse(localStorage.getItem('user')).token;

        const response = await fetch(`${apiUrl}/channels`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: channel.id, name: channel.name, domain: channel.domain, status: channel.status, template:channel.template, products: channel.products?.map(p => { return p }) })
        })
        const data = await response.json()
        console.log("returned data: ", data)
        setChannel({ ...data, products: data.products, template: data.template || 'xxx'})
        setStoredChannel({ ...data, products: data.products })

    }


    const hasChanged = (channel, storedChannel) => {
        return JSON.stringify(channel) !== JSON.stringify(storedChannel)
    }


    useEffect(() => {

        console.log('Base URL', baseUrl)
        // setPreviewUrl(`http://localhost:3000/declarations/channel/${id}/product/${channel.products[0]?.ean}/html?template=${channel.template}`)
        setPreviewUrl(`${baseUrl}/declarations/channel/${id}/product/{{ product.variants[0].barcode }}/html?template=${channel.template}`)
    }, [channel])

    const [previewUrl, setPreviewUrl] = useState(`http://localhost:4000/marketplace/channels/${id}/product/{{ product.variants[0].barcode }}/html?template=${channel.template}`)

    return (
        <Container maxWidth={'xl'}>
            <h1><i className="fa-regular fa-pen-to-square"></i> Edit Channel</h1>
            <Stack direction={'row'} justifyContent={'flex-start'} spacing={1} sx={{ mb: 1 }}>
                <ECButton startIcon={<ArrowBack />} label="Back" onClick={() => window.history.back()} />
                {hasChanged(channel, storedChannel) && <>

                    <ECButton startIcon={<Undo />} label="Undo Changes" onClick={() => setChannel(storedChannel)} />
                    <ECButton startIcon={<Save />} label="Save Changes" variant={'contained'} onClick={() => postChannel()} />
                </>}

            </Stack>

            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <ECCard>
                        <h2>Channel Information</h2>
                        <Stack direction={'column'} spacing={1}>
                            <ECInput label="Name" value={channel.name} fullWidth={true} onChange={(e) => setChannel({ ...channel, name: e.target.value })} />
                            <ECInput label="URL" value={channel.domain || ''} placeholder={'https://'} onChange={(e) => setChannel({ ...channel, domain: e.target.value })} fullWidth={true} type={'url'} />
                        </Stack>
                    </ECCard>
                </Grid>

                {1 == 2 &&
                    <Grid item xs={12} md={6}>
                        <ECCard>
                            <h2>Channel Stauts </h2>
                            <Stack direction={'column'} spacing={1}>
                                <Autocomplete
                                    renderInput={(params) => <ECInput {...params} label="Status" />}
                                    options={['Active', 'Inactive']}
                                    value={channel.status}
                                    onChange={(event, newValue) => {
                                        setChannel({ ...channel, template: newValue })
                                    }}
                                />

                            </Stack>
                        </ECCard>
                    </Grid>
                }
                {channel?.products &&

                    <Grid item xs={12} md={6}>
                        <ECCard>
                            <h2>Products</h2>
                            <Box>
                                {channel?.products?.length <= 0 ? <p>No products selected</p> :
                                    <DataGrid
                                        autoHeight={false}
                                        sx={{ maxHeight: 300 }}
                                        rows={channel?.products}
                                        columns={[
                                            { field: 'name', headerName: 'Name', flex: 0.8 },
                                            { field: 'image', headerName: 'Image', flex: 0.2, renderCell: (params) => <img src={params.row.image_url} style={{ width: 50 }} alt={params.row.name} /> }
                                        ]}
                                        onRowDoubleClick={(e) => {
                                            navigate('/products/' + e.row.id)
                                        }}
                                    />
                                }
                                <ECButton onClick={() => setIsProductSelectDialogOpen(true)} sx={{ mt: 1 }}>Select Products</ECButton>
                            </Box>

                            <ProductSelectDialog open={isProductSelectDialogOpen} onSelect={
                                (e) => {
                                    setChannel({ ...channel, products: (e) })
                                }
                            }
                                handleClose={() => { setIsProductSelectDialogOpen(false) }} />
                        </ECCard>
                    </Grid>
                }
                {1 == 2 &&
                    <Grid item xs={12} md={6}>
                        <ECCard>
                            <h2>Widget Integration</h2>
                            <p>Widgets can be integrated into your website to display the proof points of your products.</p>

                            <Accordion>
                                <AccordionSummary>
                                    <svg width="32px" height="32px" viewBox="-18 0 292 292" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid"><path d="M223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-1.703-1.703-5.029-1.185-6.32-.805-.19.056-3.388 1.043-8.678 2.68-5.18-14.906-14.322-28.604-30.405-28.604-.444 0-.901.018-1.358.044C129.31 3.407 123.644.779 118.75.779c-37.465 0-55.364 46.835-60.976 70.635-14.558 4.511-24.9 7.718-26.221 8.133-8.126 2.549-8.383 2.805-9.45 10.462C21.3 95.806.038 260.235.038 260.235l165.678 31.042 89.77-19.42S223.973 58.8 223.775 57.34zM156.49 40.848l-14.019 4.339c.005-.988.01-1.96.01-3.023 0-9.264-1.286-16.723-3.349-22.636 8.287 1.04 13.806 10.469 17.358 21.32zm-27.638-19.483c2.304 5.773 3.802 14.058 3.802 25.238 0 .572-.005 1.095-.01 1.624-9.117 2.824-19.024 5.89-28.953 8.966 5.575-21.516 16.025-31.908 25.161-35.828zm-11.131-10.537c1.617 0 3.246.549 4.805 1.622-12.007 5.65-24.877 19.88-30.312 48.297l-22.886 7.088C75.694 46.16 90.81 10.828 117.72 10.828z" fill="#95BF46" /><path d="M221.237 54.983c-1.055-.088-23.383-1.743-23.383-1.743s-15.507-15.395-17.209-17.099c-.637-.634-1.496-.959-2.394-1.099l-12.527 256.233 89.762-19.418S223.972 58.8 223.774 57.34c-.201-1.46-1.48-2.268-2.537-2.357" fill="#5E8E3E" /><path d="M135.242 104.585l-11.069 32.926s-9.698-5.176-21.586-5.176c-17.428 0-18.305 10.937-18.305 13.693 0 15.038 39.2 20.8 39.2 56.024 0 27.713-17.577 45.558-41.277 45.558-28.44 0-42.984-17.7-42.984-17.7l7.615-25.16s14.95 12.835 27.565 12.835c8.243 0 11.596-6.49 11.596-11.232 0-19.616-32.16-20.491-32.16-52.724 0-27.129 19.472-53.382 58.778-53.382 15.145 0 22.627 4.338 22.627 4.338" fill="#FFF" /></svg>
                                    Shopify</AccordionSummary>
                                <AccordionDetails>
                                    <Stack direction={'column'} alignItems={'stretch'} spacing={1}>
                                        <ol>


                                            <li>
                                                <Box sx={{ mt: 1 }}>
                                                    <ECInput rows={4} multiline label="HTML Snippet" value={widgetSnippetShopify} fullWidth={true} type={'url'} />
                                                    <ECButton label="Copy to Clipboard" startIcon={<ContentPasteOutlined />} onClick={() =>
                                                        navigator.clipboard.writeText(widgetSnippetShopify)



                                                    } />
                                                </Box>
                                            </li>
                                            <li>
                                                Save the file
                                            </li>
                                            <li>
                                                Done
                                            </li>
                                        </ol>

                                    </Stack>
                                </AccordionDetails>
                            </Accordion>



                        </ECCard>

                    </Grid>

                }

                <Grid item xs={12} md={12}>
                    <ECCard>
                        <h2>Preview</h2>
                        <Autocomplete 
                        options={['default', 'chips', 'dark']}
                        value={channel.template}
                        onChange={(event, newValue) => {
                            setChannel({ ...channel, template: newValue })
                        }
                        }
                        renderInput={(params) => <ECInput {...params} label="Template" />}
                        />
                        <small>{previewUrl}</small>
                        <iframe src={previewUrl.replace(`{{ product.variants[0].barcode }}`, channel?.products[0]?.ean)} width="100%" height="200px" style={{ border: 'none' }}></iframe>
                    </ECCard>
                </Grid>

                <Grid item xs={12} md={12}>
                    <ECCard>
                        <h2>Code</h2>
                        <ECInput multiline rows={10} fullWidth={true}
                            value={`
<iframe src="${previewUrl}" width="100%" height="200px" style={{ border: 'none' }}></iframe>`}>
                        </ECInput>
                    </ECCard>
                </Grid>


            </Grid>




        </Container>

    )
}

export default ChannelEdit;