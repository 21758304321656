import { Container, Box, Grid, Stack, Toolbar, IconButton, Drawer } from '@mui/material'
const ECCard = ({children}) => {
    const style = {
            backgroundColor: "#f8f8f8",
            padding: "0.1em 1em 1em 1em",
            height: "100%",
            alignItems: "stretch",
            borderRadius: "5px",
    }
    return (
        <Box sx={style}>
            {children}
        </Box>
    )
}


export default ECCard