import { Checkbox, FormControlLabel } from "@mui/material";

const ECCheckBox = ({ label, checked, onChange }) => {
    return (

        <FormControlLabel
            control={<Checkbox checked={checked} onChange={(e) => {
                onChange(e.target.checked)
            }}  />}
            label={label}
        />
    )
}

export default ECCheckBox;