import { Alert, Card, Container, Grid, List, ListItem } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import ECCard from "../components/ECCard"
import ECButton from "../components/ECButton"
import Badge from '../assets/level_badge.svg'
import { useEffect, useState } from "react"
const Home = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate()

    const [tasks, setTasks] = useState([])

    useEffect(() => {
        setTasks([...tasks,
        { type: 'warning', message: 'Profile Incomplete. Please complete the Profile' },
        { type: 'warning', message: 'Choose a subscription plan' }])

    }, [])



    return (
        <Container maxWidth='xl'>
            <h1>Dashboard</h1>

            <Grid container spacing={2} direction={'row'} alignItems={'stretch'}>
                <Grid item xs={12} sm={12}>
                    <ECCard>
                        <h2>First Steps</h2>
                        <List>
                          
                        </List>
                    </ECCard>
                </Grid>
                {1 == 2 &&
                    <Grid item xs={12} sm={6} >
                        <ECCard>
                            <h2>12 active connections</h2>
                            <p>You have 12 active connections.</p>
                            <p>4 new connections were added in the last week.</p>
                            <ECButton label="View Connections" onClick={() => navigate('/connections')} />
                        </ECCard>
                    </Grid>
                }

            </Grid>

        </Container>
    )
}

export default Home
