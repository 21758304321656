import { Alert, Box, Button, LinearProgress, MobileStepper, Stack, Typography } from "@mui/material"
import ECInput from "../../components/ECInput"
import ECButton from "../../components/ECButton"
import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AuthController from "../../controller/AuthController"
import CountryPickerMUI from "../../components/CountryPicker"
import { KeyboardArrowLeft } from "@mui/icons-material"
const SignupForm = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const slug = useParams().slug

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const parameterAction = searchParams.get('action')
    const parameterEmail = searchParams.get('email')
    const parameterToken = searchParams.get('code')



    const [data, setData] = useState({ email: '', password: '' })
    const [step, setStep] = useState(0)

    const stepName = {
        0: 'Email',
        1: 'Confirm Email',
        2: 'Set Password',
        3: 'User Details',
        4: 'Thank you'
    }

    useEffect(() => {
        if (parameterAction === 'confirm' && parameterEmail && parameterToken) {
            AuthController.confirmEmail(parameterEmail, parameterToken).then(d => {
                if (d) {
                    setStep(2);
                } else {
                    setStep(1)
                    setError('Failed to confirm email 3');
                }
            })
        }
        if (parameterEmail) setData({ ...data, email: parameterEmail })
    }, [parameterAction, parameterEmail, parameterToken])

    const onSignup = async () => {
        try {
            setIsLoading(true);
            setError(false);
            const user = { email, password }
            setIsLoading(false);
            props.setLoggedIn(true);
            props.setEmail(user.email);
            localStorage.setItem('user', JSON.stringify(user));
            setStep(4);
        } catch (error) {
            console.error('Failed to signup:', error);
            setIsLoading(false);
            setError('Failed to signup ' + error.message);
        }
    }

    const commitEmail = async (e) => {
        e.preventDefault();
        const result = await AuthController.commitEmail(data.email);
        if (result) {
            localStorage.setItem('user', JSON.stringify({ email: data.email }));
        }
        setStep(1);
    }

    const commitPassword = async (e) => {
        e.preventDefault();
        const result = await AuthController.commitPassword(data.email, data.password)
        if (result) {
            setStep(3);
            setData({ ...data, email: parameterEmail, token: result.token })
            await localStorage.setItem('user', JSON.stringify({ email: data.email, token: result.token }));
        }

    }

    useEffect(() => {
        props.updateStep(step)
        if (step === 2) {
            navigate('/signup', { replace: true })
        }
        setError(null)

    }, [step])

    useEffect(()=> {
        const readUser =  async () => {
        }

        readUser()

    },[])

    return (
        <Box sx={{ mt: 0 }}>

            <Stack direction={'column'} flex={1} flexDirection={'row'}>
                <Button onClick={() => setStep(0)}><KeyboardArrowLeft /></Button>
                <Box>
                    <Typography variant="caption">{stepName[step]}</Typography>

                    <MobileStepper variant="dots" steps={5} position='static' activeStep={step} />
                </Box>
            </Stack>
            {step === 0 &&
                <Stack direction={'column'} spacing={2}>
                    <ECInput label="Marketplace" fullWidth value={slug} disabled />
                    <hr />
                    <ECInput label="Email" id="email" autoComplete="email" name="email" type="email" fullWidth value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                    <ECButton label="Continue" fullWidth onClick={commitEmail} />

                    <p>
                        <small>
                            If you don't have an account yet, you can sign up <a href="/signup">here</a>
                        </small>
                    </p>
                </Stack>
            }

            {step === 1 &&
                <Stack direction={'column'} spacing={2}>
                    <p>We've just sent you an e-Mail to <br /><b>{data.email}</b></p>
                    <p><MarkEmailUnreadOutlinedIcon sx={{ width: '64px' }} /></p>
                    <p>Please check your inbox and click on the link to continue.</p>
                    <p>If you don't see the e-Mail, please check your spam folder or <a href="#" onClick={() => setStep(0)}>review</a> the e-mail address</p>
                    <p>For security reasons the code will be valid for 60 minutes only.</p>
                </Stack>
            }

            {step === 2 &&
                <Stack direction={'column'} spacing={2}>
                    <p>Your e-Mail has been confirmed!</p>
                    <p>Please choose a password and confirm it to continue.</p>
                    <input type="hidden" name="email" value={data.email} id="email" />
                    <ECInput label="Password" autoComplete={'new-password'} fullWidth id="new-password" type="password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
                    <ECInput label="Confirm Password" autoComplete={'new-password'} fullWidth id="new-password" type="password" value={data.password_confirm} onChange={(e) => setData({ ...data, password_confirm: e.target.value })} />
                    <ECButton label="Continue" fullWidth onClick={commitPassword} disabled={data.password != data.password_confirm} />
                </Stack>

            }

            {step === 3 &&
                <Stack direction={'column'} spacing={2}>
                    <p>Your password has been set!</p>
                    <h3>
                        User Details
                    </h3>
                    <ECInput label="First Name" fullWidth value={data.first_name} onChange={(e) => setData({ ...data, first_name: e.target.value })} />
                    <ECInput label="Last Name" fullWidth value={data.last_name} onChange={(e) => setData({ ...data, last_name: e.target.value })} />
                    <Stack direction={'row'}>
                        <ECInput label="Phone" fullWidth value={data.phone} onChange={(e) => setData({ ...data, phone: e.target.value })} />
                    </Stack>
                    <CountryPickerMUI label="Country" value={data.country} onChange={(e) => setData({ ...data, country: e.target.value })} />

                    <ECButton label="Continue" fullWidth onClick={onSignup} />
                </Stack>
            }


            {step === 4 &&
                <Stack direction={'column'} spacing={2}>

                    <p>Thank you for signing up!</p>
                    <p>You can now <a href="/login">login</a> to your account.</p>
                </Stack>
            }

            {error && <Alert severity="error">{error}</Alert>}
            {isLoading && <LinearProgress />}


            {1 == 2 && <pre>
                {JSON.stringify(data, null, 2)}

            </pre>}
        </Box >
    )
}

export default SignupForm