
import { Container, Stack } from '@mui/material';
import Logo from '../../assets/ec_logo.svg';
import ECInput from '../../components/ECInput';
import ECButton from '../../components/ECButton';
const apiUrl = process.env.REACT_APP_API_URL;

const LoginPage = () => {


    const postLogin = () => {
        // Fetch the plans from the backend
        const result = fetch(`${apiUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: 'email', password: 'password' }),
        })
            .then(response => response.json())
            .then(data => {
                window.location.href = data.url
            })
        


       localStorage.setItem('user', JSON.stringify({ token: 'token' }))
    }


    return (
        <Container sx={{ p: 2 }}>
            <img src={Logo} alt="EC Logo" width={'100px'} />
            <h1>Login Page</h1>
            
            <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
                <ECInput label="Email" fullWidth />
                <ECInput label="Password" fullWidth />
                <ECButton variant="contained" color="primary" fullWidth
                onClick={()=> {postLogin()}}>Login</ECButton>
                <hr/>
                <center>
                    <small>
                    If you do not have an account, please register here.
                    </small>
                </center>
                <ECButton variant="outlined" color="primary" fullWidth>Register</ECButton>
            </Stack>

        </Container>
    );
}

export default LoginPage;