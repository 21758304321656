import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles';

import ECButton from '../components/ECButton'
import ECInput from '../components/ECInput'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import Logo from '../assets/ec_logo.svg'
import LeafIcon from '../assets/faleaf.svg'
import { Container, Box, Grid, Stack, Toolbar, IconButton, Drawer } from '@mui/material'


import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

import { ChevronLeft } from '@mui/icons-material'
import AuthController from '../controller/AuthController';

const menuItems = require('./brand_menu.json')



const BrandLayout = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate()

    const [plan, setPlan] = useState(null)

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);



    const drawerWidth = 240;
    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };


    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('user')).token;

        AuthController.getPlan(token).then(d => {
            setPlan(d);
        })

    }, [])
    const drawer = (
        <Stack direction={'column'} spacing={2} sx={{ p: 2 }} justifyContent={'space-between'} height={'100%'}>

            <Box sx={{ m: 2 }}>
                <img src={Logo} alt="EC logo" width="100%" />
                <List>
                    {menuItems.map((item, index) => (
                        <Box sx={{ mt: 2 }} key={index} >
                            <ListItem key={index} disablePadding>
                                {item.subMenu ? <ListItemText primary={item.label} sx={{ pl: 2, fontWeight: 700 }} /> :
                                    <ListItemButton onClick={() => navigate(item.dir)} >
                                        <ListItemIcon>
                                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                        </ListItemIcon>
                                        <ListItemText primary={item.label} />
                                    </ListItemButton>
                                }

                            </ListItem>

                            {item.subMenu &&
                                <>
                                    {item.subMenu.map((subItem, subIndex) => (
                                        <ListItem key={subIndex} disablePadding sx={{ pl: 2 }}>
                                            <ListItemButton onClick={() => navigate(subItem.dir)}>
                                                <ListItemIcon sx={{ width: 32, p: 0, minWidth: 0 }}>
                                                    <i className={subItem.icon} style={{ color: '#000000' }}></i>
                                                </ListItemIcon>
                                                <ListItemText primary={subItem.label} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </>

                            }
                        </Box>
                    ))}
                </List>
            </Box>

            <Divider />

            {plan && <Box>
                {plan?.code === "free" &&
                <Box sx={{textAlign:'center'}}>
                Your current plan is <b>{plan?.name}</b><br/>
                To unlock all features, please upgrade to a premium plan.<br/>
                    <ECButton onClick={() => navigate('/subscription')} variant="contained" color="primary" fullWidth>Upgrade</ECButton>
                </Box>
                
                }

            </Box>
            }
            <Divider />

            <List>
                <ListItem sx={{ pl: 2 }}   >
                    <ListItemButton onClick={() => navigate('/logout')} >
                        <ListItemIcon sx={{ width: 32, p: 0, minWidth: 0 }}>
                            <i className="fa-solid fa-person-through-window" style={{ color: '#000000' }}></i>
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Stack >
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? () => window().document.body : undefined;


    return (
        <Box sx={{ display: 'flex' }}>


            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar sx={{ mr: 2, display: { sm: 'none' } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                        ECOCHECK
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >

                <Box sx={{ height: { xs: 48, sm: 0 } }}></Box>
                {props.children}
            </Box>
        </Box>
    );

}



export default BrandLayout