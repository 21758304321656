import { Box, ButtonBase, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import ECButton from "../../components/ECButton";
import ECInput from "../../components/ECInput";
import { useTranslation } from "react-i18next";
const apiUrl = process.env.REACT_APP_API_URL;

const ProofPointSelectDialog = ({ handleClose, open, onSelect }) => {


    const { t } = useTranslation()
    const [selectedValue, setSelectedValue] = useState(null);

    const [searchTerm, setSearchTerm] = useState('')
    const [availableProofPoints, setAvailableProofPoints] = useState([
        { id: 1, name: 'Proof Point 1' },
        { id: 2, name: 'Proof Point 2' },
        { id: 3, name: 'Proof Point 3' },
        { id: 4, name: 'Proof Point 4' },
    ])

    const [filterGroupCode, setFilterGroupCode] = useState(null)

    const [filteredProofPoints, setFilteredProofPoints] = useState([])

    const fetchProofPoints = async () => {
        // fetch proof points from API
        const response = await fetch(`${apiUrl}/proofpoints`)
        const data = await response.json()
        setAvailableProofPoints(data.data)
        setFilteredProofPoints(data.data.sort((a, b) => a.ProofPointGroup.code.localeCompare(b.ProofPointGroup.code)).sort((a, b) => a.name.localeCompare(b.name)))
        setSearchTerm('')
    }

    useEffect(() => {
        fetchProofPoints()
    }
        , [])



    useEffect(() => {
        console.log("setting category to " + filterGroupCode)
        setFilteredProofPoints(availableProofPoints.filter(pp => {
            return pp.name.toLowerCase().includes(searchTerm.toLowerCase())

        }).filter(pp => {
            if (filterGroupCode === null) return true;
            return pp.ProofPointGroup.code === filterGroupCode
        })
        )
    }, [searchTerm, filterGroupCode])


    const [selectedProofPoint, setSelectedProofPoint] = useState(null);

    const GroupChip = ({ code }) => {
        let color = 'primary';
        color = t('groups.' + code + '.color');

        if (!code) color = 'default';

        if (filterGroupCode === code) return <Chip label={code} sx={{ bgcolor: color, boxShadow: '1px 1px 0px #00000080' }} />;
        return <Chip label={code} sx={{ bgcolor: color }} />
    }
    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        
                <DialogTitle >Select Proof Point

                    <ECInput label="Search" placeholder="Search for a proof point" fullWidth value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <Stack direction={'row'} spacing={1} justifyContent={''}>
                        <ButtonBase onClick={() => { setFilterGroupCode('waste') }}><GroupChip code={'waste'}></GroupChip></ButtonBase>
                        <ButtonBase onClick={() => { setFilterGroupCode('climate') }}><GroupChip code={'climate'}></GroupChip></ButtonBase>
                        <ButtonBase onClick={() => { setFilterGroupCode('nature') }}><GroupChip code={'nature'}></GroupChip></ButtonBase>

                        <ButtonBase onClick={() => { setFilterGroupCode('social') }}><GroupChip code={'social'}></GroupChip></ButtonBase>

                        <ButtonBase onClick={() => { setFilterGroupCode('security') }}><GroupChip code={'security'}></GroupChip></ButtonBase>

                        <ButtonBase onClick={() => { setFilterGroupCode(null) }}><GroupChip code={'all'}></GroupChip></ButtonBase>
                    </Stack>

                    {filterGroupCode}
                </DialogTitle>

                <DialogContent>

                    {!selectedProofPoint ?
                        <Box sx={{ mt: 1 }}>


                            <List>
                                {filteredProofPoints.map((proofPoint) => (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <img src={proofPoint.image_url} />
                                        </ListItemAvatar>
                                        <ListItemButton onClick={() => { onSelect(proofPoint); handleClose() }} key={proofPoint.id}>
                                            {t('proof_points.' + proofPoint.code + '.label')}
                                        </ListItemButton>
                                        <ListItemSecondaryAction>
                                            <GroupChip code={proofPoint.ProofPointGroup?.code} />

                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}

                            </List>

                        </Box>
                        :
                        <Box sx={{ mt: 1 }}>
                            <p>
                                <b>{selectedProofPoint.name}</b>

                            </p>
                            <p>
                                <ECButton label="Change" onClick={() => { setSelectedProofPoint(null) }} />
                            </p>
                        </Box>
                    }
                </DialogContent>


                <DialogActions>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
                        <ECButton label="Close" onClick={handleClose} />
                    </Stack>

                </DialogActions>

         
        </Dialog>
    );
}

export default ProofPointSelectDialog;