const apiUrl = process.env.REACT_APP_API_URL;


const getFetch = async (dir) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const url = `${apiUrl}/${dir}`
    const options = {
        headers: {

        }
    }
    return fetch(url, options)
}

const getPostJSON = async (dir, body) => {
    try {

        const url = `${apiUrl}/${dir}`
        const options = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        }
        return fetch(url, options)
    } catch (error) {
        console.error('Error making request:', error);
        return null
    }
}

const getPostForm = async (dir, body) => {

    const url = `${apiUrl}/${dir}`
    const options = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
        body: body
    }
    return fetch(url, options)
}

const login = async (email, password) => {
    try {

        const res = await getPostJSON('auth/login', { email: email, password: password })
        const data = await res.json()
        return data

    } catch (error) {
     //   console.error('Error logging in:', error);
        return { success: false, error: error.message }
    }
}

const verifyToken = async (token) => {
    try {

        const res = await fetch(`${apiUrl}/auth/verify`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            }
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.error('Error verifying token:', error);
        return null
    }
}

const commitEmail = (e) => {
    getPostJSON('auth/commitEmail', { email: e })
        .then(response => response.json())
        .then(data => {
            return data.success
        }
        )
        .catch(error => console.error('Error commiting email:', error));
}

const confirmEmail = async (email, code) => {
    return await getPostJSON('auth/confirmEmail', { email: email, code: code })
        .then(response => response.json())
        .then(data => {
            if (data.success) return true
            else return false
        }
        )
        .catch(error => {
            console.error('Error confirming email:', error)
            return false
        }
        );
}

const commitPassword = async (email, password) => {


    return getPostJSON('auth/commitPassword', { email: email, password: password })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                return data
            } else {
                return null
            }
        }
        )
        .catch(error => {
            console.error('Error commiting password:', error)
            return false
        }
        );
}

const getPlan = async (token) => {
    
    //return { code: "free", name: "Free", description: "Free Plan", price: 0}
    const res = await fetch(`${apiUrl}/brand/plan`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
        }
    })
    const data = await res.json()
    return data.data
}

const fetchUser = async (token) => {
    try {
        const res = await fetch(`${apiUrl}/users/me`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            }
        })
        const data = await res.json()
        return data.user
    } catch (error) {
        console.error('Error fetching user:', error);
        return null
    }
}

const postNewPassword = async (token, password) => {
    try {
        const res = await fetch(`${apiUrl}/auth/password`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            method: 'POST',
            body: JSON.stringify({ password: password })
        })
        const data = await res.json()
        return data
    } catch (error) {
        console.error('Error posting new password:', error);
        return null
    }
}



export default {
    commitEmail,
    confirmEmail,
    commitPassword,
    login,
    verifyToken,
    getPlan,
    fetchUser,
    postNewPassword
};