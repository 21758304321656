import { Box, CircularProgress, Container, Grid, Skeleton, Stack, Typography } from "@mui/material";
import ECButton from "../../components/ECButton";
import { useEffect, useState } from "react";
import ECCard from "../../components/ECCard";

const PaywallPage = ({ setActivePlan }) => {


    const [selected, setSelected] = useState(null)

    const [plans, setPlans] = useState(null)

    const [interval, setInterval] = useState('month')

    const fetchStripePlans = () => {
        // Fetch the plans from the backend
        const result = fetch('http://localhost:4000/stripe/plans', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setPlans(data.data)
            })
        // setPlans(plans)

    }

    const fetchStripeCheckout = (plan) => {
        // Fetch the plans from the backend
        const result = fetch('http://localhost:4000/stripe/checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ plan }),
        })
            .then(response => response.json())
            .then(data => {
                window.location.href = data.url
            })
        // setPlans(plans)

    }

    useEffect(() => {
        fetchStripePlans()
    }, [])

    useEffect(() => {

        if (!plans) return
        if (plans.filter(plan => plan.recurring.interval === interval).length = 1) {
            setSelected(plans.filter(plan => plan.recurring.interval === interval)[0].id)
        }


    }, [interval, plans]);

    function capitalizeFirstLetter(string) {
        return string.toUpperCase()
    }

    const renderPackage = (plan) => {
        if (plan.recurring.interval !== interval) return (<></>)
        return (
            <Grid item xs={12} sm={plans.length > 2 ? 6 : 12}>

                <Box sx={
                    selected !== plan.id ? { border: '1px solid #00000020', borderRadius: 1, bgcolor: '#fff', p: 2, pt: 0, width: '100%' } :
                        { border: '1px solid #00000040', boxShadow: '2px 3px 2px #00000020', borderRadius: 1, bgcolor: '#eeffee75', p: 2, pt: 0, width: '100%' }}>
                    <h1>{plan.product.name}</h1>
                    <p>{plan.product.description}</p>
                    <ul>
                        {plan.product.features?.map((feature, index) => (
                            <li key={index}>{feature.name}</li>
                        ))}
                    </ul>
                    <hr />


                    <Stack direction="row" spacing={1} justifyContent={'space-between'} alignItems={'center'}>
                        <span>
                            <><span sx={{ fontSize: 'larger' }}><Typography variant={'h5'} sx={{ p: 0 }}><b>{((plan.unit_amount / 100).toFixed(2) + '').replace('.', ',')}</b> {capitalizeFirstLetter(plan.currency)}</Typography></span> per {plan.recurring.interval}</>
                        </span>
                        {selected !== plan.id ?
                            <>
                                <ECButton variant="outlined" color="primary" fullWidth
                                    onClick={() => setSelected(plan.id)}>Select</ECButton>
                            </> :
                            <>
                                <ECButton variant="contained" color="primary" fullWidth
                                    onClick={() => { fetchStripeCheckout(plan); setActivePlan(plan.id) }}>Checkout</ECButton>
                            </>}

                    </Stack>

                </Box>
            </Grid>
        )
    }
    return (
        <>
            <Box sx={{ pb: 2 }}>
                <h1>Please Upgrade</h1>
                <p>
                    To unlock all features, please upgrade to a premium plan.

                </p>
                <p>

                    <Stack direction="row" spacing={1} justifyContent={'center'} alignItems={'center'}>

                        <Box>
                            <ECButton variant={interval == 'month' ? "contained" : "outlined"} color="primary"
                                onClick={() => setInterval('month')}>
                                monthly
                            </ECButton>
                            <ECButton variant={interval == 'year' ? "contained" : "outlined"} color="primary" sx={{ ml: -0.5 }}
                                onClick={() => setInterval('year')}>

                                yearly
                            </ECButton>
                        </Box>
                        <Box>

                        </Box>
                    </Stack>
                </p>
                <Grid container spacing={2}>



                    {plans ?
                        <>
                            {plans?.map((plan, index) => (
                                renderPackage(plan)
                            ))}
                        </>
                        :
                        <>
                            <Container>
                                <Box sx={{ height:'200px', justifyContent: 'center', alignContent: 'center', alignItems: 'center', textAlign: 'center', p: 2 }}>
                                    <CircularProgress />
                                </Box>
                            </Container>
                        </>
                    }


                </Grid>
                <p>You will be redirected to an external payment service.</p>
            </Box>
        </>
    );
}
export default PaywallPage;