import { Alert, Stack } from "@mui/material"
import ECButton from "../../components/ECButton"
import ECInput from "../../components/ECInput"
import { useEffect, useState } from "react"
import AuthController from "../../controller/AuthController"

const LoginForm = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('r')


    const onButtonClick = async (e) => {
        e.preventDefault()


        setError('')



        AuthController.login(email, password).then(d => {
            if (d) {
               if (d.success) {
                localStorage.setItem('user', JSON.stringify({ email: d.user.email, token: d.token }))
                props.setLoggedIn(true);
               } else {
                setError('Failed to login: '+d.error);

               }
            } else {
                setError('Could not connect to server');
            }
        })
    }

    useEffect(() => {
        setError('')
    }, [email, password])

    return (
        <form onSubmit={(e) => { onButtonClick(e) }}>
            <Stack direction={'column'} spacing={2}>


                <h2>
                    Log in
                </h2>
                <ECInput type="email" id="email" name="Email" placeholder="E-Mail" label="Email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
                <ECInput type="password" placeholder="Password" label="Password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
                {error && <Alert variant='standard' color="error">{error}</Alert>}
                <ECButton type="submit" label="Log in" fullWidth />

            </Stack>
        </form>
    )



}

export default LoginForm