import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import AuthController from "../../controller/AuthController";
import ECCard from "../../components/ECCard";
import ECInput from "../../components/ECInput";
import ECButton from "../../components/ECButton";



const User = () => {

    const [user, setUser] = useState(null);

    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    useEffect(() => {

        const fetchUser = async () => {
            const token = JSON.parse(localStorage.getItem('user')).token;
            try {
                const fetchedUser = await AuthController.fetchUser(token);
                setUser(fetchedUser);
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        }


        fetchUser();

    }, [])

    // on submit:
    const postNewPassword = async () => {

        try {


            const token = JSON.parse(localStorage.getItem('user')).token;
            const response = await AuthController.postNewPassword(token, newPassword);

        } catch (error) {
            console.error('Failed to post new password:', error);
        

        }

    }

    return (
        <Container maxWidth={'xl'}>
            <h1>User Details</h1>


            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <p>
                        Email: {user?.email}
                    </p>
                </Grid>
               
                <Grid item xs={12} md={6}>
                  
                        <                ECCard            >
                            <h3>Password</h3>

                            <form onSubmit={()=>postNewPassword()}>
                                <Stack direction={'column'} spacing={1}>
                                    <ECInput label={'New Password'} type={'password'} onChange={(e)=>setNewPassword(e.target.value)}/>
                                    <ECInput label={'Confirm New Password'} type={'password'} onChange={(e)=>setConfirmNewPassword(e.target.value)} />
                                    <ECButton onClick={()=>postNewPassword()} label={'Change Password'} fullWidth={false} disabled={(newPassword!=confirmNewPassword || newPassword.length<6) } />
                                </Stack>

                            </form>

                        </ECCard>
           

                </Grid>
            </Grid>




        </Container >
    )
}

export default User