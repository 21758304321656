import { Box, TextField } from "@mui/material"

const ECInput = ({ type, id, value, onChange, autoComplete, label, placeholder, fullWidth, ...props }) => {
    return (
        <Box flexDirection={'column'} flex={1}>


            <TextField
                {...props}
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                label={label}
                autoComplete={autoComplete}
                placeholder={placeholder}
                fullWidth={fullWidth}
            />
        </Box>
    )
}

export default ECInput