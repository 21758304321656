import { Box, Container, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import ProductController from "../../controller/ProductController";
import { useNavigate } from "react-router-dom";
import ECButton from "../../components/ECButton";
import { Add, UploadFile } from "@mui/icons-material";


const Products = () => {

    const navigate = useNavigate()

    const [products, setProducts] = useState([])


    useEffect(() => {
        ProductController.getProducts().then(d => {
            setProducts(d)
        })
    }, [])

    return (
        <Container maxWidth="xl">
            <h1><i className="fa-solid fa-boxes" />  Products</h1>
            <Box sx={{ mb: 1 }}>
                <Stack direction="row" spacing={1} justifyContent={'flex-start'}>
                    <ECButton startIcon={<Add />} label="Add Product" onClick={()=> navigate('/products/new')}/>
                    <ECButton startIcon={<UploadFile />} label="Import Products" />
                </Stack>
            </Box>
            <DataGrid rows={products} sx={{height:'800px'}} columns={[
                { field: 'ean', headerName: 'EAN', flex: 0.15 },
                {
                    field: 'image', headerName: 'Image', flex: 0.05, renderCell: (params) => {
                        return <>
                         {params.row.image_url ?
                                    <img src={params.row.image_url} style={{ width: 50 }} alt={params.row.name} />:
                                    <i class="fa-solid fa-box"></i>}
                                    </>
                    }
                },
                { field: 'name', headerName: 'Name', flex: 0.8 },
                { field: 'channel', headerName: 'Channels', flex: 0.2, renderCell: (params) => {
                    return <Box sx={{color: params.row.ProductOnChannel?.length<=0 ? '#ff0000':'#000'}}>{params.row.ProductOnChannel?.length}</Box>
            
                }}
            ]}
                onRowDoubleClick={(e) => {

                    navigate('/products/' + e.row.id)

                }}
            />


        </Container>
    );
}

export default Products;