import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ECButton from '../components/ECButton'
import ECInput from '../components/ECInput'
import Logo from '../assets/ec_logo.svg'
import { Container, Box, Grid, Stack } from '@mui/material'
import LoginForm from './login/LoginForm'
const Logout = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem('user')
        props.setLoggedIn(false)
        navigate('/')
    }, [])

    return (
        <Container maxWidth={'sm'}>

            <p>
                Logout sucessfull
            </p>

        </Container >
    )
}

export default Logout