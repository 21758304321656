import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Snackbar, Stack } from "@mui/material"
import ECInput from "../../components/ECInput"
import { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import BrandController from '../../controller/BrandController'; // Adjust the path as necessary
import ECButton from "../../components/ECButton";

const Brands = (props) => {

    const [brands, setBrands] = useState([])
    const [filteredBrands, setFilteredBrands] = useState([])
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [searchTerm, setSearchTerm] = useState('')
    const [selectedIds, setSelectedIds] = useState([])
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    useEffect(() => {
        const loadBrands = async () => {
            try {
                setIsLoading(true);
                setError(false);
                const fetchedBrands = await BrandController.fetchBrands();
                setBrands(fetchedBrands);
                setIsLoading(false);
            } catch (error) {
                console.error('Failed to fetch brands:', error);
                setIsLoading(false);
                setError('Failed to fetch brands ' + error.message);
            }
        };

        loadBrands();
    }, [])

    useEffect(() => {
        if (!brands.length>0) return;
        setFilteredBrands(brands?.filter(brand => brand.name.toLowerCase().includes(searchTerm.toLowerCase())));
    }, [brands, searchTerm])



    return (
        <Container maxWidth={"xl"}>


            <Box sx={{ mt: 3 }}>
                <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                    <h1>Brands</h1>
                    <Box>
                        <small>{selectedIds.length} brands selected </small>
                        <ECButton disabled={selectedIds.length<=0} label="Establish Connection" fullWidth onClick={() => setShowConfirmDialog(true)} />
                    </Box>
                </Stack>

                <ECInput label="Search" placeholder="Search for a brand" fullWidth value={searchTerm} onChange={(e)=>setSearchTerm(e.target.value)}/>


                <small>
                    We found {filteredBrands.length} brands for you
                </small>
            </Box>

            {error && <p>{error}</p>}
            {isLoading && <p>Loading...</p>}

            <DataGrid rows={filteredBrands} onRowSelectionModelChange={(e)=> {setSelectedIds(e)}}checkboxSelection columns={[
                { field: 'name', headerName: 'Name', flex: 1 },
                { field: 'products', headerName: 'products listed', flex: 1 },
                { field: 'connections', headerName: 'connections', flex: 1 },
                { field: 'proofPoints', headerName: 'proof points', flex: 1 },
                {
                    field: 'avg. amount', headerName: 'avg. amt. proof points', flex: 1, renderCell: (params) => {
                        return params.row.products > 0 ? Math.round(params.row.proofPoints * 10 / params.row.products) / 10 : 0
                    }
                }

            ]} />



            <Dialog open={showConfirmDialog} onClose={() => setShowConfirmDialog(false)}>
                <DialogTitle>

                </DialogTitle>
                <DialogContent>
                    <p>
                        You are about to establish a connection with the following brands:
                    </p>
                    <List>
                        {selectedIds.map(id => {
                            const brand = brands.find(brand => brand.id === id);
                            return <ListItem key={id}>
                                <ListItemAvatar>
                                    <img src={`https://picsum.photos/seed/${id}/50/50`} alt={brand.name} />
                                </ListItemAvatar>
                                <ListItemText primary={brand.name} secondary={`${brand.products} products listed`} />
                                <ListItemSecondaryAction>
                                    <ECButton label="Remove" onClick={() => setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    </List>
                    <p>
                        Are you sure you want to establish a connection with the selected brands?
                    </p>
                </DialogContent>
                <DialogActions>
                    <ECButton label="Cancel" onClick={() => setShowConfirmDialog(false)} />
                    <ECButton label="Confirm" onClick={() => setShowConfirmDialog(false)} />
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Brands