import { useEffect, useState } from "react"
import ECCard from "../../components/ECCard"
import ECButton from "../../components/ECButton"
import { TranscribeOutlined } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"

const { Container, List, ListItem, Grid, Stack, Box } = require("@mui/material")




const Subscription = () => {
    const navigate = useNavigate()

    const {webhook} = useParams()

    const [availablePlans, setAvailablePlans] = useState([])

    const [interval, setInterval] = useState('month')

    const fetchPlansFromStripe = async () => {
        const response = await fetch('http://localhost:4000/stripe/plans')
        const data = await response.json()
        setAvailablePlans(data.data)
        return data
    }

    useEffect(() => {
        fetchPlansFromStripe()
    }, [])


    useEffect(() => {
        if (webhook==='payment-success') {
            alert('Payment Success')
        }

        if (webhook==='payment-cancel') {
            alert('Payment Cancelled')
        }
    }, [webhook])


    const Amount = ({ amount, currency }) => {
        return <Box sx={{fontSize:'2em'}}>{amount / 100}&nbsp;€</Box>
    }

    const SwitchButton = ({ children }) => {
        return <Stack direction={'row'} spacing={'-1px'}>
            {children}
        </Stack>
    }


    const PerformCheckout = async (id) => {
        const response = await fetch('http://localhost:4000/stripe/checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ plan: { id: id } })
        })

        const data = await response.json()
        window.open(data.url, '_blank', 'noopener,noreferrer');
    }

    return (
        <Container maxWidth={'xl'}>
            <h1>Subscription</h1>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <SwitchButton>
                        <ECButton label="Monthly" onClick={() => setInterval('month')} variant={interval == 'month' ? 'contained' : 'outlined'} />
                        <ECButton label="Yearly" onClick={() => setInterval('year')} variant={interval == 'year' ? 'contained' : 'outlined'} />
                    </SwitchButton>
                </Grid>

                {availablePlans?.filter(p => p.recurring?.interval == interval).map((plan, index) => {
                    return <Grid item sm={4} key={index}>

                        <ECCard>
                            <Stack direction='column' justifyContent={'space-between'} height='100%' spacing={2}>
                                <Box>
                                    <h2>{plan.product.name}</h2>
                                    <p>{plan.product.description}</p>
                                    <b>Features:</b>
                                    <ul>
                                        {plan.product.features.map((feature, index) => {
                                            return <li key={index}>{feature.name}</li>
                                        })}

                                    </ul>
                                </Box>
                                <Box>
                                    <Stack direction={'row'} spacing={2}>

                                        <div class="mr2 flex-item width-fixed">
                                            <span class="CurrencyAmount">
                                                <Amount amount={plan.unit_amount} />
                                            </span>
                                        </div>
                                        <Box sx={{alignContent:'center', mt:2}}>
                                            <span class="ProductSummaryTotalAmount-billingInterval Text Text-color--gray500 Text-fontSize--14">
                                                <Box sx={{lineHeight:'0.8em'}} >per<br/>{interval}</Box>
                                            </span>
                                        </Box>

                            </Stack>

                            <ECButton label="Subscribe" fullWidth={true} onClick={() => PerformCheckout(plan.id)} />
                        </Box>
                    </Stack>
                        </ECCard>
        </Grid>
                })}
            </Grid >

        </Container >
    )


}

export default Subscription