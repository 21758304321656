
import { BrowserRouter, Route, Routes, Switch, useNavigate, Navigate } from 'react-router-dom'
import Home from './pages/home'
import Login from './pages/login'
import Logout from './pages/logout'
import Register from './pages/register'
import './App.css'
import { useEffect, useState } from 'react'
import Layout from './pages/layout'
import Connections from './pages/Connections'
import Brands from './pages/brands/Brands'
import Transfers from './pages/transfers/Transfers'
import BrandLayout from './pages/BrandLayout'
import RestaurantLayout from './pages/RestaurantLayout'
import Profile from './pages/brands/Profile'
import AuthController from './controller/AuthController'
import Subscription from './pages/brands/Subscription'

import Products from './pages/products/Products'
import ProofPoints from './pages/proofpoints/ProofPoints'
import ProductEdit from './pages/products/ProductEdit'

import { PlanProvider, usePlan } from './context/PlanContext';
import User from './pages/user/User'
import ProofPointEdit from './pages/proofpoints/ProofPointEdit'
import Channels from './pages/channels/Channels'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './i18n';
import ChannelEdit from './pages/channels/ChannelEdit'
import MainPage from './pages/restaurant/MainPage'
import SubmitPage from './pages/restaurant/SubmitPage'
import ErrorBoundary from './components/ErrorBoundary'
import AdminPage from './pages/admin/AdminPage'

function App() {

  const [loggedIn, setLoggedIn] = useState(false)

  const [role, setRole] = useState('restaurant')


  const checkToken = async () => {
    const user = await localStorage.getItem('user')
    if (user) {
      const data = await JSON.parse(user);

      if (data.token) {
        AuthController.verifyToken(data.token).then(d => {
          if (d?.success) {
            setLoggedIn(true)

          } else {
            console.error('token not valid');
            setLoggedIn(false)
            return <Navigate to="/login" />
          }
        })
      } else {
        setLoggedIn(false)
      }

    }
  }

  useEffect(() => {
    checkToken()
  }, [])

  const PublicRoutes = () => {
    return (
      <Routes>
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route path="/signup" element={<Register setLoggedIn={setLoggedIn} />} />
        <Route path="/" element={<Login setLoggedIn={setLoggedIn} />} />

      </Routes>
    )
  }

  const ProtectedRoutes = () => {
    if (role === 'brand') {
      return (
        <Routes>
          <Route exact path="/" element={<BrandLayout><Home /></BrandLayout>} />
          <Route path="/profile" element={<BrandLayout><Profile /></BrandLayout>} />
          <Route path="/products" element={<BrandLayout><Products /></BrandLayout>} />
          <Route path="/products/:id" element={<BrandLayout><ProductEdit /></BrandLayout>} />

          <Route path="/proofpoints" element={<BrandLayout><ProofPoints /></BrandLayout>} />
          <Route path="/proofpoints/:id" element={<BrandLayout><ProofPointEdit /></BrandLayout>} />
          <Route path="/channels" element={<BrandLayout><Channels /></BrandLayout>} />
          <Route path='/channels/:id' element={<BrandLayout><ChannelEdit /></BrandLayout>} />
          <Route path='/subscription' element={<BrandLayout><Subscription /></BrandLayout>} />
          <Route path='/subscription/:webhook' element={<BrandLayout><Subscription /></BrandLayout>} />

          <Route path='/user' element={<BrandLayout><User /></BrandLayout>} />
          <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn} />} />


        </Routes>
      )
    }
    if (role === 'restaurant') {
      return (
        <Routes>
          <Route exact path="/" element={<RestaurantLayout><Home /></RestaurantLayout>} />
          <Route path="/profile" element={<RestaurantLayout><Profile /></RestaurantLayout>} />
          <Route path="/products" element={<RestaurantLayout><Products /></RestaurantLayout>} />
          <Route path="/products/:id" element={<RestaurantLayout><ProductEdit /></RestaurantLayout>} />

          <Route path="/proofpoints" element={<RestaurantLayout><ProofPoints /></RestaurantLayout>} />
          <Route path="/proofpoints/:id" element={<RestaurantLayout><ProofPointEdit /></RestaurantLayout>} />
          <Route path="/channels" element={<RestaurantLayout><Channels /></RestaurantLayout>} />
          <Route path='/channels/:id' element={<RestaurantLayout><ChannelEdit /></RestaurantLayout>} />
          <Route path='/subscription' element={<RestaurantLayout><Subscription /></RestaurantLayout>} />
          <Route path='/subscription/:webhook' element={<RestaurantLayout><Subscription /></RestaurantLayout>} />

          <Route path='/user' element={<RestaurantLayout><User /></RestaurantLayout>} />
          <Route path="/logout" element={<Logout setLoggedIn={setLoggedIn} />} />

          <Route path="/admin" element={<><AdminPage /></>} />

          <Route path="/main" element={<><MainPage /></>} />
          <Route path="/main/summary" element={<><SubmitPage /></>} />
        </Routes>
      )
    }
    if (role === 'marketplace') {
      return (
        <Routes>
          <Route exact path="/" element={<Layout><Home /></Layout>} />
          <Route path="/brands" element={<Layout><Brands /></Layout>} />
          <Route path="/transfers" element={<Layout><Transfers /></Layout>} />
          <Route
            path="*"
            element={<Navigate to="/" replace={true} />}
          />
        </Routes>

      )
    }


    // localStorage.removeItem('user')
    // setLoggedIn(false)

    return (
      <Routes>
        <Route path="/" element={<><Navigate to='/' /></>} />
      </Routes>
    )
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>

            {loggedIn ?
              <PlanProvider>
                <ProtectedRoutes />
              </PlanProvider>
              :
              <PublicRoutes />
            }

          </I18nextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </div>

  )

}

export default App
