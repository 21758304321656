import React from 'react'
import { useNavigate } from 'react-router-dom'
import ECButton from '../components/ECButton'
import ECInput from '../components/ECInput'
import Logo from '../assets/ec_logo.svg'
import { Container, Box, Grid, Stack, MobileStepper } from '@mui/material'
import SignupForm from './login/SignupForm'


const Register = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate()

    const onButtonClick = () => {
        alert("try to login")
        if (loggedIn) {
            localStorage.removeItem('user')
            props.setLoggedIn(false)
        } else {
            navigate('/login')
        }
    }

    const [step, setStep] = React.useState(null)

    return (
        <Box className={'mainContainer'} sx={{ p: 1 }}>

            <Grid container spacing={2} sx={{ width: { sm: '600px' } }}  >

                <Grid item xs={12} sm={4} sx={{ textAlign: 'center', mr: { xs: 0, sm: 1 }, alignItems: 'center', height: '100%', mt: 1 }} >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/0/01/Picnic_logo.svg" width="0%" alt="Picnic logo" />

                    <img src={Logo} alt="EC logo" width="100%" />

                    <h3>
                        Signup
                    </h3>
                    <small>
                        If you already have an account. Please go to <a href="/">Login</a>
                    </small>

                </Grid>
                <Grid item xs={12} sm={6} sx={{ borderLeft: { sm: '1px solid #707070', xs: 'unset' }, textAlign: 'center' }} >
                    <SignupForm setLoggedIn={props.setLoggedIn} setEmail={props.setEmail} updateStep={setStep} />

                </Grid>
            </Grid>


        </Box >
    )
}

export default Register