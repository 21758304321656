import { Box, Button, Container, Input, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";
import Logo from '../../assets/ec_logo.svg';
import { useNavigate } from "react-router-dom";
import ECButton from "../../components/ECButton";
import { Check, InfoOutlined, Warning, WarningAmberOutlined } from "@mui/icons-material";
import ECCheckBox from "../../components/ECCheckBox";
import { useState } from "react";
import ECInput from "../../components/ECInput";
import ECCard from "../../components/ECCard";
import { styled } from '@mui/material/styles';

const SubmitPage = ({ selected, renderStatus, setSubmitting }) => {

    const navigate = useNavigate();

    const [agree, setAgree] = useState(false)
    const [agreeUpdate, setAgreeUpdate] = useState(false)

    const Input = styled('input')({
        display: 'none'
    });


    return (
        <>

            <Box sx={{ mt: 1 }}>

                <h2>Please review the data:</h2>
                <ECCard>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>

                        <List>
                            {selected.filter(i => i.verification === 'Self Assessment').map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemAvatar sx={{ alignItems: 'flex-start' }}>
                                        {renderStatus(item)}
                                    </ListItemAvatar>

                                    <ListItemText primary={<><b>{item.name}</b></>}
                                        secondary={item.verification === 'Proof Documentation' ? <Box sx={{ color: '#ff4400' }}>
                                            <b>Upload document: </b>
                                            {item.details}<br />
                                            <label htmlFor="contained-button-file">
                                                <Input accept="*" id="contained-button-file" multiple type="file" />
                                                <Button variant="outlined" component="span" fullWidth>
                                                    Upload
                                                </Button>
                                            </label>
                                        </Box> : <></>}

                                    />

                                </ListItem>
                            ))
                            }
                        </List>
                    </Stack>
                    <p>
                        Please upload proof documentation for the items marked with a red exclamation mark.
                    </p>
                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>


                        <List>
                            {selected.filter(i => i.verification === 'Proof Documentation').map((item, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemAvatar sx={{ alignItems: 'flex-start' }}>
                                        {item.verification === 'Proof Documentation' ? <WarningAmberOutlined color="success" /> :
                                            renderStatus(item)
                                        }
                                    </ListItemAvatar>

                                    <ListItemText primary={<><b>{item.name}</b></>}
                                        secondary={item.verification === 'Proof Documentation' ? <Box sx={{ color: '#ff4400' }}>
                                            <b>Upload document: </b>
                                            {item.details}<br />
                                            <label htmlFor="contained-button-file">
                                                <Input accept="*" id="contained-button-file" multiple type="file" />
                                                <Button variant="outlined" component="span" fullWidth>
                                                    Upload
                                                </Button>
                                            </label>
                                        </Box> : <></>}

                                    />

                                </ListItem>
                            ))
                            }
                        </List>



                    </Stack>
                </ECCard>
                <Box sx={{ mb: 2, mt: 2 }}>
                    <ECCard>

                        <p>If you want to add additional information, please do so here:</p>
                        <ECInput label="Additional Information" multiline rows={4} fullWidth={true} />
                    </ECCard>
                </Box>
                <Box>
                    <Stack direction={'column'} spacing={2} justifyContent={'space-between'}>
                        <Box sx={!agree ? { border: '2px dotted #fa0', bgcolor: '#ffc', p: 2 } : { border: '2px solid #0c0', bgcolor: '#cfc', p: 2 }}>
                            <ECCheckBox label="I agree to the terms and conditions. I understand that the data is correct and I am responsible for the data."
                                onChange={(e) => setAgree(!agree)}
                                checked={agree} />
                        </Box>
                        <Box sx={!agreeUpdate ? { border: '2px dotted #fa0', bgcolor: '#ffc', p: 2 } : { border: '2px solid #0c0', bgcolor: '#cfc', p: 2 }}>
                            <ECCheckBox label="I agree and understand that I will inform the EC team if the data is incorrect. I understand that I am responsible for the data."
                                onChange={(e) => setAgreeUpdate(!agreeUpdate)}
                                checked={agreeUpdate} />
                        </Box>
                        <ECButton
                            disabled={!agree || !agreeUpdate}
                            fullWidth variant="contained" color="primary" onClick={() => { setSubmitting(true) }}>
                            Submit
                        </ECButton>
                    </Stack>
                </Box>
            </Box>

        </>
    )

}

export default SubmitPage;