import { Box, Container, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ECButton from "../../components/ECButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_URL;


const ProofPoints = () => {

    const navigate = useNavigate()

    const [declarations, setDeclarations] = useState([])

    const [groupByCode, setGroupByCode] = useState([])
    const [declarationRows, setDeclarationRows] = useState([])

    const {t} = useTranslation()
    useEffect(() => {
        const fetchDeclarations = async () => {
            const response = await fetch(`${apiUrl}/declarations`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': '' + JSON.parse(localStorage.getItem('user')).token || ''
                    }
                })

            const data = await response.json()
            setDeclarations(data.data)
        }
        fetchDeclarations()
    }, [])

    useEffect(() => {
        const groupByCode = declarations?.reduce((acc, item) => {
            if (!acc[item.ProofPoint.code]) {
                acc[item.ProofPoint.code] = []
            }
            acc[item.ProofPoint.code].push({ id: item.id, ...item })
            return acc
        }, {})
        setGroupByCode(groupByCode)
    }, [declarations])

    useEffect(() => {
        let rows = []
        declarations?.forEach((item) => {
            const proofPoint = item.ProofPoint
            const group = item.DeclarationGroup



            if (!rows.find((row) => row.DeclarationGroup?.id === group.id)) {
                const products = declarations.filter((declaration) => declaration.DeclarationGroup.id === group.id).map((declaration) => declaration.Product)
                rows.push({
                    id: item.id,
                    ProofPoint: proofPoint,
                    Products: products,
                    DeclarationGroup: item.DeclarationGroup
                })
            }
        })
        setDeclarationRows(rows)
    }, [declarations])

    return (
        <Container maxWidth="xl">
            <h1><i className="fa-solid fa-list-check" />  Proof Points</h1>
            <Box sx={{ mb: 1 }}>
                <ECButton label="Add Proof Point" onClick={() => { navigate(('/proofpoints/new')) }} />
            </Box>
            <DataGrid rows={declarationRows} columns={[

                {
                    field: 'name', headerName: 'Proof Point', flex: 0.2, renderCell: (params) =>
                            <Stack direction={'row'} spacing={1} alignItems='center' justifyContent={'space-between'}><img src={params.row.ProofPoint.image_url} height={40} width={40}/> <b>{t('proof_points.'+params.row.ProofPoint.code+'.label')}</b></Stack>,
                            sortingOrder: ['asc', 'desc']
                } ,
                {
                    field: 'product', headerName: 'Product', flex: 0.8, renderCell: (params) => {
                        return <Box sx={{}}>{params.row.Products.map((product) => {
                            return <>
                            {product.image_url ? <img src={product.image_url} height={24} /> : <i class="fa-solid fa-box"></i>}
                            
                            </>
                        })}</Box>
                    }
                }
            ]}
                onRowDoubleClick={(params) => navigate(`/proofpoints/${params.row.DeclarationGroup.id}`)} />

        </Container>
    );
}

export default ProofPoints;