import { Container, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import ECCard from "../../components/ECCard";
import { useState } from "react";
import ECButton from "../../components/ECButton";
import { Link } from "react-router-dom";
import { CancelOutlined, Delete, DeleteOutlineOutlined, Key, Remove } from "@mui/icons-material";
import ChannelSection from "./ChannelSection";

const Channels = () => {


    const [channels, setChannels] = useState([])

    return (
        <Container maxWidth={'xl'}>
            <h1><i class="fa-solid fa-bullhorn"></i> Publish</h1>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <ECCard>
                     <ChannelSection />
                    </ECCard>
                </Grid>
              

              
            </Grid>
        </Container>
    );
}



export default Channels;