import { Snackbar, Box, Alert, AlertTitle, Container, Stack } from "@mui/material"
import ECInput from "../../components/ECInput"

const Profile = () => {

    return (
        <Container maxWidth={'xl'}>
            <h1>Profile</h1>
            <Alert variant="outlined" severity="warning" sx={{mb:1}}>
                <AlertTitle>
                    Profile incomplete
                </AlertTitle>
                Please complete your profile to access all features.<br />

            </Alert>


                <form>
                    <Stack direction={'column'} spacing={2}>
                        <ECInput type="text" id="name" name="Name" placeholder="Name" label="Name" fullWidth />

                    </Stack>
                </form>     
        </Container>
    )
}
export default Profile