import { Button, IconButton } from "@mui/material"

const ECButton = ({ children, startIcon, submit, type, fullWidth, variant, disabled, ...props }) => {
    return (
        <Button
            {...props}
            type={type}
            startIcon={startIcon}
            style={{ width: props.fullWidth ? '100%' : 'auto' }}
            fullWidth={fullWidth}
            value={children || props.label}
            submit={submit}
            disabled={disabled}
            variant={variant || "outlined"}
        >{children || props.label}</Button>
    )
}

export default ECButton
