import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import ECInput from './ECInput';

const CountryPickerMUI = ({ onChange, currentValue }) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(currentValue || null);

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                const countryData = data.map(country => ({
                    value: country.cca2,
                    label: country.name.common,
                    flag: country.flags.svg
                }));
                setCountries(countryData);
            });
    }, []);

    useEffect(() => {
        setSelectedCountry(countries.find(c => c.value === currentValue));  // Update the selectedCountry whenever currentValue changes
    }, [countries, currentValue]);

    return (
        <>
            <Autocomplete
                value={selectedCountry} // Set value
                onChange={(event, newValue) => {
                    setSelectedCountry(newValue);
                    if (onChange && newValue) {
                        onChange(newValue.value);
                    }
                }}
                options={countries}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props}>
                        <img src={option.flag} alt={option.label} width="20" style={{ marginRight: '10px' }} />
                        {option.label}
                    </li>
                )}
                renderInput={(params) => (
                    <ECInput {...params} label="Country" variant="filled" fullWidth
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    {selectedCountry && (
                                        <img src={selectedCountry.flag} alt={selectedCountry.label} width="20" style={{ marginRight: '10px' }} />
                                    )}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }} />
                )}
            />
        </>
    );
}

export default CountryPickerMUI;
