import { useEffect, useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import ECCard from "../../components/ECCard";
import ECInput from "../../components/ECInput";
import { Container, Box, Stack, Grid, Tooltip, Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, ListItemButton, ListItemAvatar } from "@mui/material";
import ECButton from "../../components/ECButton";
import { ArrowBack, CheckBoxOutlineBlank, DataObjectOutlined, HdrOnSelect, Redo, RemoveCircle, Save, Undo } from "@mui/icons-material";
import ProofPointSelectDialog from "./ProofPointSelectDialog";
import ProductSelectDialog from "./ProductSelectDialog";
import { useTranslation } from 'react-i18next';
import UploadSection from "./UploadSection";
import ECCheckBox from "../../components/ECCheckBox";
const apiUrl = process.env.REACT_APP_API_URL;

const ProofPointEdit = () => {

    const { id } = useParams();
    const [product, setProduct] = useState(null);

    const [proofPoint, setProofPoint] = useState(null);
    const [products, setProducts] = useState([])

    const [declaration, setDeclaration] = useState({})

    const [declarations, setDeclarations] = useState([])

    const [declarationGroup, setDeclarationGroup] = useState({ details: {} })

    const [showProofPointSelect, setShowProofPointSelect] = useState(false);
    const [showProductSelect, setShowProductSelect] = useState(false);

    const [reviewCheck, setReviewCheck] = useState(false)


    const navigate = useNavigate();

    const { t } = useTranslation()

    useEffect(() => {
        setDeclarations(products.map(p => {
            return {
                proofPoint: { id: proofPoint?.id, code: proofPoint?.code },
                product: { id: p.id, name: p.name },
                details: {
                    comment: declaration.comment
                }
            }
        }))
    }, [proofPoint, products, declaration])


    useEffect(() => {
        setDeclarationGroup({
            ...declarationGroup,
            declarations: declarations
        })
    }, [declarations])


    const fetchDeclaration = async () => {
        if (id == 'new') return;

        const token = JSON.parse(localStorage.getItem('user')).token;

        const response = await fetch(`${apiUrl}/declarations/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            }
        })

        const data = await response.json()
        return data.data

    }

    useEffect(() => {
        fetchDeclaration().then(decs => {
            if (!decs) return;
            setDeclarations(decs);
            setDeclarationGroup({ ...declarationGroup, details: decs[0]?.DeclarationGroup.details, documents: decs[0]?.DeclarationGroup.details.documents})
            setProofPoint(decs[0]?.ProofPoint)
            setProducts(decs.map(d => d.Product))
        })
    }, [id])

    const postDeclarations = async () => {
        const token = JSON.parse(localStorage.getItem('user')).token;
        const response = await fetch(`${apiUrl}/declarations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            body: JSON.stringify({ ...declarationGroup, id: id })
        })
        const data = await response.json()
    }

    const deleteDeclaration = async () => {
        const token = JSON.parse(localStorage.getItem('user')).token;
        const response = await fetch(`${apiUrl}/declarations/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            }
        })
        const data = await response.json()
    }

    return (
        <Container maxWidth={'xl'}>
            <h1><i class="fa-regular fa-pen-to-square"></i> Edit Proof Point</h1>
            <Stack direction={'row'} justifyContent={'flex-start'} spacing={1} sx={{ mb: 1 }}>
                <ECButton startIcon={<ArrowBack />} label="Back" onClick={() => window.history.back()} />

            </Stack>
          
            <Stack direction={'column'} justifyContent={'space-between'} spacing={1} sx={{ mb: 1, height: '100%' }}>
                <Box>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <ECCard sx={{ pt: 2 }}>
                                <Stack direction="column" spacing={2} justifyContent={'space-between'} sx={{ height: '100%', pt: 2 }}>
                                    <h2><i class="fa-solid fa-list-check"></i> Choose Proof Point</h2>
                                    {proofPoint ? <Box>
                                        <Stack direction={'row'} spacing={2}>
                                            {proofPoint?.image_url ? <img src={proofPoint.image_url} style={{ height: 64 }} alt={proofPoint.name} /> : <i class="fa-solid fa-box"></i>}
                                            <Box>
                                                <p><h2>{t('proof_points.' + proofPoint.code + '.label')}</h2></p>
                                                <p>{proofPoint.name}</p>
                                            </Box>
                                        </Stack>
                                    </Box>
                                        :
                                        <Box sx={{ textAlign: 'center' }}><i>please select a proof point </i></Box>
                                    }
                                    <ECButton label="Select" onClick={() => { setShowProofPointSelect(true) }} />
                                    <ProofPointSelectDialog open={showProofPointSelect} onSelect={(pp) => setProofPoint(pp)} handleClose={() => setShowProofPointSelect(false)}
                                        sx={{ minWidth: '600px' }} />
                                </Stack>
                            </ECCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ECCard>
                                <Stack direction="column" spacing={2} justifyContent={'space-between'} sx={{ height: '100%', pt: 2 }}>

                                    <h2><i class="fa-solid fa-boxes"></i> Choose Product(s) </h2>
                                    <small>{products.length} selected</small>
                                    {products?.length > 0 ? <Box>
                                        <Box direction={'row'} spacing={2}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '320px', overflowY: 'scroll' }}>
                                                {products.map((p) => {
                                                    return <Tooltip title={p.name}>
                                                        <>
                                                            {p.image_url ? <img src={p.image_url} style={{ height: 64 }} alt={p.name} /> : <span><i class="fa-solid fa-box"></i>{p.name}</span>}
                                                        </>
                                                    </Tooltip>
                                                })}
                                            </Box>

                                        </Box>
                                    </Box>
                                        :
                                        <Box sx={{ textAlign: 'center' }}><i>please select at least one product</i></Box>
                                    }
                                    <ECButton label="Select" onClick={() => { setShowProductSelect(true) }} />
                                    <ProductSelectDialog open={showProductSelect} preSelected={products} onSelect={(ps) => setProducts(ps)} handleClose={() => setShowProductSelect(false)} />
                                </Stack>
                            </ECCard>
                        </Grid>
                        {products?.length > 0 && proofPoint ?
                            <>
                                <Grid item xs={12} md={12}>
                                    <ECCard>
                                        <Stack direction="column" spacing={2} justifyContent={'space-between'} sx={{ height: '100%', pt: 2 }}>
                                            <h2><i class="fa-solid fa-clipboard-list"></i> Declaration Details</h2>
                                            <p><b></b>{t('proof_points.' + proofPoint.code + '.info')}</p>
                                            {1 == 1 &&
                                                <Grid container>
                                                    <Grid item xs={12} md={6} sx={{ pr: 1 }}>
                                                        <b> Required information: </b><br />
                                                        <i class="fa-regular fa-circle-check"></i> {t('proof_points.' + proofPoint.code + '.evidence')}

                                                        <p>
                                                            <ECInput label="Comment" multiline rows={3} value={declarationGroup.details.comment} fullWidth onChange={(e) => setDeclarationGroup({ ...declarationGroup, details: { ...declarationGroup.details, comment: e.target.value } })} />
                                                        </p>
                                                        <p>
                                                            <ECInput label="Valid Until" type="date" value={declarationGroup.details?.validUntilDate} fullWidth onChange={(e) => setDeclarationGroup({ ...declarationGroup, details: { ...declarationGroup.details, validUntilDate: e.target.value } })} />
                                                        </p>

                                                    </Grid>


                                                    <Grid item xs={12} md={6} sx={{ pl: 1 }}>
                                                        <b>Uploaded document(s):</b>

                                                        <UploadSection declarationGroup={declarationGroup} setDeclarationGroup={setDeclarationGroup} />


                                                    </Grid>


                                                </Grid>
                                            }


                                        </Stack>
                                    </ECCard>
                                </Grid>
                              
                                <Grid item xs={12} md={12}>
                                    <ECCard>


                                        <Stack direction={'column'} justifyContent={'flex-end'} spacing={1} sx={{ mt: 2 }}>
                                            <ECCheckBox value={reviewCheck} onChange={(e) => setReviewCheck(e)} label={'I have reviewed and approved the proof point. I confirm that the proof point is accurate and up to date.'} />

                                            <ECButton disabled={!reviewCheck} startIcon={<Save />} label="Save & Submit" variant={'contained'} onClick={() => {
                                                postDeclarations().then(() =>

                                                    navigate('/proofpoints')
                                                )
                                            }} />
                                        </Stack>
                                    </ECCard>
                                </Grid>
                            </>
                            : null}




                    </Grid>
                </Box>
                <Box>

                    <ECButton label="Delete" startIcon={<RemoveCircle />} variant="text" onClick={() => {
                        deleteDeclaration();
                        navigate('/proofpoints')
                    }
                    } />



                </Box>
            </Stack>


        </Container>
    );
}

export default ProofPointEdit;