// ExportController.js
const apiUrl = process.env.REACT_APP_API_URL;


const getFetch = async (dir) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const url = `${apiUrl}/${dir}`
    const options = {
        headers: {
            Authorization: `${user.token}`
        }
    }
    return fetch(url, options)
}

const getPost = async (dir, body) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const url = `${apiUrl}/${dir}`
    const options = {
        headers: {
            Authorization: `${user.token}`
        },
        method: 'POST',
        body: JSON.stringify(body)
    }
    return fetch(url, options)
}


// Fetch all brands
const fetchExports = async () => {
    try {
        const response = await getFetch('publisher/export')
        const data = await response.json();
        return data
    } catch (error) {
        console.error('Error fetching brands:', error);
        throw error;
    }
};

const postExport = async (exportData) => {
    try {
        const response = await getPost('publisher/export', {})

        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error('Error adding new brand:', error);
        throw error;
    }
}


export default {
    fetchExports,
    postExport
};
