import { Badge, Box, Chip, Container, Grid, List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import ECButton from "../../components/ECButton"
import ECCard from "../../components/ECCard"

import ExportController from "../../controller/ExportController"
import { useEffect, useState, useRef } from "react"

const Transfers = () => {


    const [exports, setExports] = useState([])

    const timeoutRef = useRef();

    const [timeoutMS, setTimeoutMS] = useState(1000)
    const [lastUpdated, setLastUpdated] = useState(new Date().toLocaleString())

    const triggerCSVExport = () => {
        ExportController.postExport()
    }

    const fetchExports = async () => {
        const data = await ExportController.fetchExports()
        setExports(data)
        setLastUpdated(new Date().toLocaleString())
    }

    useEffect(() => {
        fetchExports()

        const startTimeoutLoop = () => {
            timeoutRef.current = setTimeout(() => {
                fetchExports()

                // Call the function again to continue the loop
                startTimeoutLoop();
            }, timeoutMS); // Adjust the delay as needed
        };

        // Start the loop
        startTimeoutLoop();

        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [])

    return (
        <Container maxWidth={'xl'}>
            <h1>Transfers</h1>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ECCard>
                        <h2>Transfers</h2>
                        <List>
                            <ListItem>
                                <ListItemText primary="CSV Export" secondary={new Date().toLocaleString()} />
                                <ListItemSecondaryAction>
                                    <Chip badgeContent={4} color="warning" label="scheduled" />
                                </ListItemSecondaryAction>
                            </ListItem>

                        </List>
                    </ECCard>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <ECCard>
                        <h2>Export</h2>
                        <p>Initiate a new data export</p>
                        <ECButton label="Initiate CSV Export" onClick={() => triggerCSVExport()} />
                    </ECCard>
                </Grid>
            </Grid>
            <small>last update: {lastUpdated}</small>
            <DataGrid rows={
                exports
            } columns={[
                { field: 'id', headerName: 'Id', visible: false, hidden:true, hideSortIcons:true },
                { field: 'createdAt', headerName: 'Created At', flex:0.1},
                { field: 'completedAt', headerName: 'Completed At',flex:0.1 },

                { field: 'createdBy', headerName: 'Created By' },
                { field: 'type', headerName: 'Type' },
                { field: 'action', headerName: 'Action' },
                { field: 'data', flex:0.2, headerName: 'Data', hideSortIcons: true, renderCell: (params) => { return JSON.stringify(params.row.data) } },
                { field: 'status', headerName: 'Status', renderCell: (params) => { return <Chip label={params.row.status} color={params.row.status == 'completed' ? 'success' : 'warning'} /> } },
                { field: 'url', headerName: 'URL', width:130, renderCell: (params) => { return <Box>{params.row.url ? <ECButton label="Download" onClick={() => window.open(params.row.url)} /> : <></>}</Box>}}
            ]}
                sortModel={[
                    {
                        field: 'createdAt',
                        sort: 'desc',
                    }]}

                    columnVisibilityModel={{
                        // Hide columns status and traderName, the other columns will remain visible
                        id: false,
                        createdBy: false,
                        action:false
                      }}
            />

        </Container>
    )
}
export default Transfers