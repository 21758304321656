import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import ECButton from "../../components/ECButton";
import ECInput from "../../components/ECInput";
import { AddBoxOutlined, Check, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, LibraryAddCheck } from "@mui/icons-material";
const apiUrl = process.env.REACT_APP_API_URL;


const ProductSelectDialog = ({ handleClose, open, onSelect, preSelected }) => {



    const [availableProducts, setAvailableProducts] = useState([
    ])

    const fetchProducts = async () => {
        // fetch proof points from API
        const token = JSON.parse(localStorage.getItem('user')).token;
        const response = await fetch(`${apiUrl}/products`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            }
        })
        const data = await response.json()
        setAvailableProducts(data.data)
    }

    useEffect(() => {
        fetchProducts().then(() => {
            if (preSelected) {
               // setSelectedProducts(preSelected)
            }
        })
    }, [])

    const toggle = (product) => {
        if (selectedProducts.includes(product)) {
            setSelectedProducts(selectedProducts.filter(p => p.id !== product.id))
        } else {
            setSelectedProducts([...selectedProducts, product])
        }
    }

    const toggleAll = () => {
        if (selectedProducts.length === availableProducts.length) {
            setSelectedProducts([])
        } else {
            setSelectedProducts(availableProducts)
        }
    }

    const [selectedProducts, setSelectedProducts] = useState([]);



    useEffect(() => {
    }, [selectedProducts])



    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle >Select Products

                <ECInput label="Search" placeholder="Search for a product" fullWidth />

                <ListItemButton onClick={() => { toggleAll(); }} key={1}>
                    <ListItemAvatar>
                        {selectedProducts?.length === availableProducts?.length ? <LibraryAddCheck /> : <CheckBoxOutlineBlank color="#007700" />}
                    </ListItemAvatar>
                    <ListItemText primary={selectedProducts?.length === availableProducts?.length ? 'Deselect All' : 'Select All'}>

                    </ListItemText>
                    <ListItemSecondaryAction>

                    </ListItemSecondaryAction>
                </ListItemButton>

            </DialogTitle>

            <DialogContent>

                <Box >
                    <List>



                        {availableProducts?.map((product) => (


                            <ListItemButton onClick={() => { toggle(product); }} key={product.id}>
                                <ListItemAvatar>
                                    {selectedProducts.find(sp => sp.id==product.id) ? <CheckBox /> : <CheckBoxOutlineBlank color="#007700" />}

                                </ListItemAvatar>
                                <ListItemText primary={product.name}>
                                </ListItemText>
                                <ListItemSecondaryAction>
                                    <img src={product.image_url} height={32} />
                                </ListItemSecondaryAction>
                            </ListItemButton>


                        ))}

                    </List>


                </Box>

            </DialogContent>


            <DialogActions>

                <ECButton label="Confirm" onClick={() => { onSelect(selectedProducts); handleClose() }} fullWidth variant={'contained'} />


            </DialogActions>


        </Dialog>
    );
}

export default ProductSelectDialog;