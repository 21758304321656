import { useEffect, useState } from "react";
import ECButton from "../../components/ECButton";
import { Chip, List, ListItem, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const ChannelSection = () => {


    const [channels, setChannels] = useState([])

    const navigate = useNavigate()

    const fetchChannels = async () => {
        const token = JSON.parse(localStorage.getItem('user')).token;

        const response = await fetch(`${apiUrl}/channels`, {
            headers: {
                Authorization: token
            }   
        })
        const data = await response.json()
        setChannels(data.data)
    }

    useEffect(() => {
        fetchChannels()
    }, [])

    return (
        <div>
            <h2>Channels</h2>
            {channels?.length <= 0 ? <p>No channels configured</p> :
                <>
                    {channels?.length &&
                        <DataGrid rows={channels} columns={[
                            { field: 'name', headerName: 'Name', flex: 0.8 },
{ field: 'domain', headerName: 'Domain', flex: 0.5 },
                            {
                                field: 'status', headerName: 'Status', flex: 0.2, renderCell: (params) => {
                                    return <>
                                        <Chip label={params.row.status} />
                                    </>

                                }
                            }



                        ]}
                            onRowDoubleClick={(e) => {
                                navigate('/channels/' + e.row.id)
                            }}

                            density="compact"
                        />
                    }

                </>}
            <ECButton label="Add Channel" startIcon={<Add />} sx={{ mt: 1 }} onClick={() => navigate('/channels/new')} />
        </div>
    );
}

export default ChannelSection;
