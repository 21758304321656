import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend) // Load translations using http (e.g., from your server)
  .use(LanguageDetector) // Detect language from the browser
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    fallbackLng: 'en', // Use English if detected language is not available
    lng: 'en', // Default language
    backend: {
      loadPath: '/locales/{{lng}}.json', // Path to the translation files
    },
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
